import React from 'react'
import './Deskripsi.css'

function Deskripsi() {
    return (
        <div className='deskripsi'>
            <div style={{ width: '600px', marginBottom: '30px' }}>
                <h1 className='description-title'> BEM UI 2023 </h1>
                <p className='primary-color description-text'>
                    BEM UI adalah organisasi eksekutif mahasiswa Universitas Indonesia. Organisasi ini dibentuk untuk menjadi wadah pengembangan potensi dan kreativitas mahasiswa Universitas Indonesia serta mewujudkan tata kelola kampus yang baik dan berkelanjutan.
                </p>
                <a href='/hubungi-kami' className='description-link'>Hubungi Kami ↗ </a>
            </div>


            <div style={{ width: '685px' }}>
                <iframe width='100%' height="385" style={{ boxShadow: '0 15px 20px 0 rgba(0, 0, 0, 0.3)' }}
                    src="https://www.youtube.com/embed/QWOHIHD9G7k">
                </iframe>
            </div>
        </div>



    )
}

export default Deskripsi