import React from 'react'
import ArtikelBem from '../components/Artikel/ArtikelBem'
import Banner from '../components/Artikel/Banner'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

function Artikel() {
    return (
        <div>
            <Navbar />
            <Banner />
            <ArtikelBem />
            <Footer />
        </div>
    )
}

export default Artikel