import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Layout.css'
import logo from '../img/LOGO.png'
import { Link } from "react-router-dom"

function Layout({ children }) {
    return (
        <Container fluid>
            <Row>
                <Col md={2} className='sidebar' >
                    <div className='text-center'>
                        <img style={{ marginTop: '35px' }} src={logo} width='75%' />
                    </div>
                    <hr style={{ borderTop: '2px solid #992020' }}></hr>

                    <Link className='sb-text' to={'/admin/dashboard'}>
                        Dashboard</Link>
                    <Link className='sb-text' to={'/admin/form-dokumen'}>Form Dokumen</Link>
                    <Link className='sb-text' to={'/admin/form-kajian'}>Form Kajian</Link>
                    <Link className='sb-text' to={'/admin/form-artikel'}>Form Artikel</Link>
                    <Link className='sb-text' to={'/admin/kalendar'}>Form Kalendar</Link>
                    <Link className='sb-text' to={'/admin/master-data'}>Master Data</Link>
                    <Link className='sb-text' to={'/admin/shortener'}>URL Shortener</Link>
                    <Link className='sb-text' style={{ position: 'fixed', bottom: '10px' }} to={'/admin'}>Sign out</Link>
                </Col>

                <Col md={10} className='layout'>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export default Layout