import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

function Container({ children }) {
    return (
        <div>
            <Navbar />
            <div >
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Container