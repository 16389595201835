import React from 'react'
import './Uinfo.css'
import logo from "../../../img/home/uinfo-logo.png"
import playstore from "../../../img/home/playstore.png"
import appstore from "../../../img/home/appstore.png"
import uinfoicon from "../../../img/home/uinfopicfix.png"
import { fontSize, margin } from '@mui/system'

function Uinfo() {
    return (
        <div className='uinfo-section'>

            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {/* kiri */}
                <div style={{ width: '30vw', paddingTop: '60px' }}>
                    {/* baris logo */}
                    <div className='uinfo-title-section'>
                        {/* logo dan title uinfo */}
                        <img style={{ width: '80px', height: '80px', boxShadow: '0px 1px 7px rgba(112, 144, 176, 0.5)', borderRadius: '30px' }} src={logo} />
                        <h1 className='uinfo-title-text'>UInfo</h1>
                    </div>
                    <div>
                        {/* text */}
                        <h2 style={{ fontStyle: 'italic', fontWeight: '500', fontSize: '32px', lineHeight: '39px', margin: '2px 0px' }}>Everything you need to know <br></br>only on UInfo!</h2>
                        <h2 style={{ fontWeight: '500', fontSize: '24px', lineHeight: '29px', color: '#A7A7A7', margin: '5px 0px' }}>Dari, Oleh, dan Untuk Mahasiswa</h2>
                    </div>
                    <div style={{ display: 'flex', marginTop: '30px', flexWrap: 'wrap' }}>
                        <div>
                            {/* playstore */}
                            <img style={{ marginTop: '4px', marginRight: '40px', width: '180px' }} src='https://www.suryalaya.co.id/wp-content/uploads/2019/06/get-it-on-google-play-png-apple-and-the-apple-logo-are-trademarks-of-apple-inc-registered-in-the-u-s-and-other-countries-app-store-is-a-service-mark-of-apple-inc-registered-in-826.png' />
                        </div>
                        <div >
                            {/* appstore */}
                            <img style={{ width: '180px' }} src='https://images.squarespace-cdn.com/content/v1/59d81702bebafb24a35368bf/1592152856822-EQ0KY7PLJJI3HPTRY31Z/app-store-png-logo-33123.png' />
                        </div>
                    </div>
                </div>

                {/* kanan */}
                <div style={{ width: '40vw' }}>
                    {/* iphone picture */}
                    <img width={'100%'} src={uinfoicon} />
                </div>
            </div>

        </div >
    )
}

export default Uinfo