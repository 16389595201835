import Home from './pages/Home';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from 'react';


import './admin/admin.css';
import Uinfo from './pages/Uinfo';
import Hubungi from './pages/Hubungi';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dokumentasi from './pages/Dokumentasi';
import Sikap from './components/Dokumentasi/Rilis Sikap/Sikap';
import Sikap1 from './components/Dokumentasi/Rilis Sikap/Sikap1';
import Artikel from './pages/Artikel';
import ArtikelPage from './components/Artikel/ArtikelPage';
import KajianPage from './components/Kajian/Kajian Page/KajianPage';

import Dashboard from './admin/components/Dashboard';
import Dokumen from './admin/components/Dokumen';
import KajianAdmin from './admin/components/KajianAdmin';
import ArtikelAdmin from './admin/components/ArtikelAdmin';
import Shortener from './admin/components/Shortener';
import MasterDataCategory from './admin/components/MasterDataCategory';
import SignIn from './admin/components/SignIn';
import KalenderAdmin from './admin/components/KalenderAdmin';

import './style.css';
import FungsionarisPage from './pages/Fungsionaris/FungsionarisPage';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {

  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />

              {/* <Route path="/fungsionaris/:birdepId" element={<BirdepPage birdepData={mergedData["0"]} />} /> */}
              <Route path='fungsionaris/:fungsionarisName' element={<FungsionarisPage />} />
              <Route path='dokumentasi' element={<Dokumentasi />} />
              <Route path='hubungi-kami' element={<Hubungi />} />
              <Route path='artikel' element={<Artikel />} />
              <Route path='uinfo' element={<Uinfo />} />
              <Route path='rilis-sikap' element={<Sikap />} />
              <Route path='rilis-sikap-1' element={<Sikap1 />} />
              <Route path='artikel-1' element={<ArtikelPage />} />
              <Route path='kajian-1' element={<KajianPage />} />
            </Route>


            <Route path='/admin'>
              <Route index element={<SignIn />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='form-dokumen' element={<Dokumen />} />
              <Route path='form-kajian' element={<KajianAdmin />} />
              <Route path='form-artikel' element={<ArtikelAdmin />} />
              <Route path='kalendar' element={<KalenderAdmin />} />
              <Route path='master-data' element={<MasterDataCategory />} />
              <Route path='shortener' element={<Shortener />} />
            </Route>
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </>
  );
}

export default App;
