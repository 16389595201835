import React from 'react'
import './Proker.css'
import proker1 from "../../../img/home/proker1.png"
import proker2 from "../../../img/home/proker2.png"
import proker4 from "../../../img/home/proker4.png"
import proker5 from "../../../img/home/proker5.png"
import { AiFillInstagram } from "react-icons/ai"
import { display } from '@mui/system'
import { Col, Container, Row } from 'react-bootstrap'

function Proker() {
    return (
        <Container>
            <h1 className='proker-title' style={{ margin: '50px 0px' }}>Program Kerja Unggulan</h1>
            <Row className="justify-content-md-center" style={{ marginBottom: '50px' }}>
                <Col xs lg="3" style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <img width={'100%'} src={proker1} />
                </Col>
                <Col md="auto">
                    <div style={{ width: '50vw' }}>
                        <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

                        {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
                        <h1 className='proker-sosmed' >@uimengajar</h1>

                        <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginBottom: '50px' }}>
                <Col xs lg="3">
                    <img width={'100%'} src={proker1} />
                </Col>
                <Col md="auto">
                    <div style={{ width: '50vw' }}>
                        <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

                        {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
                        <h1 className='proker-sosmed' >@uimengajar</h1>

                        <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginBottom: '50px' }}>
                <Col xs lg="3">
                    <img width={'100%'} src={proker1} />
                </Col>
                <Col md="auto">
                    <div style={{ width: '50vw' }}>
                        <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

                        {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
                        <h1 className='proker-sosmed' >@uimengajar</h1>

                        <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginBottom: '50px' }}>
                <Col xs lg="3">
                    <img width={'100%'} src={proker1} />
                </Col>
                <Col md="auto">
                    <div style={{ width: '50vw' }}>
                        <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

                        {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
                        <h1 className='proker-sosmed' >@uimengajar</h1>

                        <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{ marginBottom: '80px' }}>
                <Col xs lg="3">
                    <img width={'100%'} src={proker1} />
                </Col>
                <Col md="auto">
                    <div style={{ width: '50vw' }}>
                        <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

                        {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
                        <h1 className='proker-sosmed' >@uimengajar</h1>

                        <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

                    </div>
                </Col>
            </Row>

        </Container>





        // <div style={{ padding: '30px 180px', alignItems: 'center', boxSizing: 'border-box' }}>
        //     <h1 className='proker-title' style={{ marginBottom: '30px' }}>Program Kerja Unggulan</h1>
        //     <div >
        //         <div className='proker-container'>
        //             {/* first proker unggulan */}
        //             <div style={{ textAlign: 'center', width: '20vw', alignItems: 'center' }}>
        //                 <img width={'100%'} src={proker1} />
        //             </div>

        //             <div style={{ width: '50vw' }}>
        //                 <h2 className='proker-name'  >Gerakan UI Mengajar (GUIM)</h2>

        //                 {/* <h1 style={{}}><AiFillInstagram style={{ color: '', fontSize: '20px' }} /></h1> */}
        //                 <h1 className='proker-sosmed' >@uimengajar</h1>

        //                 <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

        //             </div>
        //         </div>

        //         {/* second proker unggulan */}
        //         <div className='proker-container'>
        //             <div style={{ textAlign: 'center', width: '20vw' }}>
        //                 <img width={'100%'} src={proker2} />
        //             </div>

        //             <div >
        //                 <span className='proker-name' >Career Talk UI</span>
        //                 <h1 className='proker-sosmed'>@careertalk.ui</h1>
        //                 <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>

        //             </div>
        //         </div>

        //         {/* third proker unggulan */}
        //         <div className='proker-container'>
        //             <div style={{ textAlign: 'center', width: '20vw' }}>
        //                 <img width={'100%'} src={proker2} />
        //             </div>

        //             <div >
        //                 <span className='proker-name' >Pesta Rakyat UI (Olim UI, OIM UI, UI Art War)</span>
        //                 <h1 className='proker-sosmed'>@olim.ui &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; @oim_ui &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; @uiartwar</h1>
        //                 <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>
        //             </div>
        //         </div>

        //         {/* fourth proker unggulan */}
        //         <div className='proker-container'>
        //             <div style={{ textAlign: 'center', width: '20vw' }}>
        //                 <img width={'100%'} src={proker4} />
        //             </div>

        //             <div>
        //                 <span className='proker-name' >UI SDGs Summit</span>
        //                 <h1 className='proker-sosmed'>@ui.sdgsummit</h1>
        //                 <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>
        //             </div>
        //         </div>

        //         {/* fifth proker unggulan */}
        //         <div className='proker-container'>
        //             <div style={{ textAlign: 'center', width: '20vw' }}>
        //                 <img width={'90%'} src={proker5} />
        //             </div>

        //             <div >
        //                 <span className='proker-name' >UI Youth Environmental Action (UIYEA)</span>
        //                 <h1 className='proker-sosmed'>@uiyea</h1>
        //                 <h1 className='proker-description'>Lorem ipsum dolor sit amet consectetur. Cursus cras mi tincidunt elit. Eu a orci tempus pharetra risus metus egestas amet ultricies. Nisi id tristique eu pulvinar.</h1>
        //             </div>
        //         </div>
        //     </div>
        // </div >
    )
}

export default Proker