import React from 'react'
import './Banner.css'
import banneruinfo from "../../img/home/banneruinfo.png"

function Banner() {
    return (
        <div className='banner-bg'>
            <div>
                <img src={banneruinfo}
                    /*gambarnya ngikutin lebar parent(parent nya itu div which is borderannya)*/
                    style={{ borderRadius: '5px 5px 0px 0px', padding: '15px 15px 0px 15px', margin: '0px', width: '100%', boxSizing: 'border-box' }}
                />
            </div>

            <h1 className='banner-text'>
                UInfo merupakan layanan <span className='banner-text-dark'>kanal informasi </span>dari Badan Eksekutif Mahasiswa Universitas Indonesia dengan berbagai fitur yang dapat <span className='banner-text-dark'>menunjang</span> kehidupan <span className='banner-text-dark'>perkuliahan mahasiswa</span> Universitas Indonesia. UInfo hadir dalam bentuk aplikasi <span className='banner-text-italic'>mobile</span> yang dapat diunduh melalui Play Store dan App Store. UInfo hadir dengan mengusung tujuh buah fitur andalan diantaranya adalah Tentang UI, Layanan Pengaduan, UIkipedia, Perekrutan Terbuka, UI Event, UIkihow, UI Data, Info Lomba, Info Karir.
            </h1>

        </div>
    )
}

export default Banner