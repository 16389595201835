import React from 'react'
import FungsionarisCard from './FungsionarisCard'

function FgLayout({ data }) {
  return Object.entries(data).map((item, index) => {
    if (item[0].includes("group")) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: '70px' }}>
          {item[1].map((i => <div>
            <div style={{ padding: '15px 0px', wordWrap: 'break-word', width: '343px', height: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h1 style={{ fontWeight: '700', fontSize: '45px', fontStyle: 'normal', color: '#B74675', textAlign: 'center' }}>
                {i.jabatan}
              </h1>
            </div>
            <FungsionarisCard img={i.image} name={i.nama} fakultas={i.fakultas} />
          </div>
          ))}
        </div>
      )
    }
    return (
      <div style={{ marginBottom: '70px' }}>
        <h1 style={{ fontWeight: '700', fontSize: '45px', fontStyle: 'normal', color: '#B74675', textAlign: 'center', marginTop: '50px' }}>
          {item[0]}
        </h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap', width: "95%", margin: 'auto' }}>
          {
            item[1].map(i => <FungsionarisCard img={i.image} name={i.nama} fakultas={i.fakultas} />)
          }
        </div>
      </div>
    )

  })
}

export default FgLayout