import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import './ArtikelBem.css'
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';

const onChange = (pageNumber) => {
    console.log('Page: ', pageNumber);
};

function ArtikelBem() {
    return (
        <Container fluid>
            <Row style={{ background: '#F3F3F3' }}>
                <Col >
                    <div style={{ padding: '0px 90px', margin: '100px 0px' }}>

                        <Card style={{ background: 'white', boxShadow: '0px 14px 40px 0px rgba(112, 144, 176, 0.20)', borderRadius: '15px', padding: '30px 50px' }}>
                            <Row className='align-items-center'>
                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <Link className='artikel-title' to={"/artikel-1"}>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</Link>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img style={{ marginLeft: '20px' }} width={'100px'} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                            <hr style={{ background: '#B74675' }}></hr>
                            <Row className='align-items-center'>

                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <p className='artikel-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</p>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img width={'100px'} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                            <hr style={{ background: '#B74675' }}></hr>
                            <Row className='align-items-center'>
                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <p className='artikel-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</p>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img width={'100px'} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                            <hr style={{ background: '#B74675' }}></hr>
                            <Row className='align-items-center'>
                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <p className='artikel-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</p>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img width={'100px'} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
            <div className='d-flex justify-content-center p-4' style={{ background: '#F3F3F3' }}>
                <Pagination defaultCurrent={1} total={500} onChange={onChange} />
                <br />
            </div>

        </Container>
    )
}

export default ArtikelBem