import React from 'react'
import { Badge, Col, Row } from 'react-bootstrap'
import './Fitur.css'
import UKM from '../../img/home/UINFO_UKM.png'
import komunitas from '../../img/home/UINFO_KOMUNITAS.png'
import paguyuban from '../../img/home/UINFO_PAGUYUBAN.png'
import uidata from '../../img/home/UINFO_UIDATA.png'
import event from '../../img/home/UINFO_UIEVENT.png'
import beasiswa from '../../img/home/UINFO_BEASISWA.png'
import karir from '../../img/home/UINFO_KARIR.png'
import lomba from '../../img/home/UINFO_LOMBA.png'
import uikihow from '../../img/home/UINFO_UIKIHOW.png'
import tomboldarurat from '../../img/home/UINFO_TOMBOLDARURAT.png'
import market from '../../img/home/UINFO_BEMARKET.png'

function Fitur() {
    return (
        <div className='fitur-section'>
            <h1 className='fitur-title'>
                Fitur-Fitur
            </h1>
            <div>
                <h1 className='fitur-subtitle'>
                    Tentang UI
                </h1>
                <Row className='justify-content-between'>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={UKM} width={'65px'} height={'85px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>UKM</h2>
                                <p className='fitur-desc'>Informasi berbagai UKM di Universitas Indonesia</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                            <img src={komunitas} width={'71px'} height={'65px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Komunitas <span className='badge-style' >Coming soon</span> </h2>
                                <p className='fitur-desc'>Informasi berbagai komunitas di Universitas Indonesia
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={paguyuban} width={'80px'} height={'73px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Paguyuban <span className='badge-style' >Coming soon</span></h2>
                                <p className='fitur-desc'>Informasi berbagai paguyuban di Universitas Indonesia</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                            <img src={uidata} width={'80px'} height={'72px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>UI Data</h2>
                                <p className='fitur-desc'>Informasi data demografi <br></br>mahasiswa UI
                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>

            <div>
                <h1 className='fitur-subtitle'>
                    Informasi
                </h1>
                <Row className='justify-content-between'>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={event} width={'68px'} height={'66px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>UI Event</h2>
                                <p className='fitur-desc'>Informasi berbagai UKM di Universitas Indonesia</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                            <img src={beasiswa} width={'71px'} height={'65px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Beasiswa</h2>
                                <p className='fitur-desc'>Informasi berbagai komunitas di Universitas Indonesia</p>

                            </div>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                            <img src={uikihow} width={'75px'} height={'75px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>UIkihow</h2>
                                <p className='fitur-desc'>Informasi berbagai komunitas di Universitas Indonesia
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={lomba} width={'75px'} height={'77px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Lomba</h2>
                                <p className='fitur-desc'>Informasi berbagai paguyuban di Universitas Indonesia</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center mb-5'>
                            <img src={karir} width={'72px'} height={'64px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Karir</h2>
                                <p className='fitur-desc'>Informasi data demografi mahasiswa UI
                                </p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>

            <div>
                <h1 className='fitur-subtitle'>
                    Tentang UI
                </h1>
                <Row className='justify-content-between'>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={market} width={'65px'} height={'72px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>BEMarket</h2>
                                <p className='fitur-desc'>Merchandise keren Universitas Indonesia</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex align-items-center mb-5'>
                            <img src={tomboldarurat} width={'82px'} height={'93px'} />
                            <div className='ms-4'>
                                <h2 className='fitur-name'>Tombol Darurat</h2>
                                <p className='fitur-desc'>Membantu kamu saat memerlukan bantuan</p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Fitur