const mergedData = {
  "pengurus-inti": {
    "cardData": {
      "Ketua dan Wakil Ketua": [
        {
          "nama": "Melki Sedek Huang",
          "fakultas": "FH 2019",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Shifa Anindya Hartono",
          "fakultas": "FMIPA 2019",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Kepala Satuan Tim Pengendali Internal": [
        {
          "nama": "Ivan Fathoni",
          "fakultas": "FMIPA 2019",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Finance Controller",
          "nama": "Ranti Rizkia Taqwiyah",
          "fakultas": "FEB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Treasurer",
          "nama": "Muhammad Rizky Lahadalia",
          "fakultas": "FEB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Vice Finance Controller",
          "nama": "Albin Wellyanto",
          "fakultas": "FEB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group1": [
        {
          "jabatan": "Koordinator Bidang Internal",
          "nama": "Fadia Fadila",
          "fakultas": "FISIP 2019",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Kepala Kantor Komunikasi Informasi",
          "nama": "Rizky Ananta",
          "fakultas": "FASILKOM 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group2": [
        {
          "jabatan": "Koordinator Bidang Sosial Lingkungan",
          "nama": "Kevin Wisnumurthi Adhi Nugroho",
          "fakultas": "FT 2019",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Koordinator Bidang Sosial Politik",
          "nama": "Taffi Hensan",
          "fakultas": "FT 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group3": [
        {
          "jabatan": "Koordinator Bidang Kemahasiswaan",
          "nama": "Juditha Danuvanya",
          "fakultas": "FIA 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Koordinator Bidang Minat Bakat",
          "nama": "Chintia Octavia",
          "fakultas": "FH 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ]
    }
  },
  "multimedia": {
    "nama": "Biro Multimedia",
    "desc": "Biro Multimedia hadir sebagai penghimpun informasi dari biro/departemen/tim di BEM UI serta diharapkan mampu menyebarkan informasi tersebut secara efektif ke IKM UI maupun masyarakat luas. Biro Multimedia juga bertanggung jawab terhadap seluruh media informasi milik BEM UI, seperti website, aplikasi, seluruh media sosial.",
    "title1": "BIRO",
    "title2": "MULTIMEDIA",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Muhammad Fiqo Anugerah",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Defani Shafa Maharani",
          "fakultas": "FH 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Arina Aunaka",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Alfredo Austin",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Alifa Nisa Fadila",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Alvaro Austin",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fitra Mulya Saputra",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rachel Heningtyas Zanetta Erari",
          "fakultas": "FASILKOM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Syarief Ahmad Al-Muhajir",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Syifa Azzahra Setiawan",
          "fakultas": "FKM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Tiva Adhisti Nafira Putri",
          "fakultas": "FASILKOM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Valentino Farish Adrian",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Vania Azria Wardani",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ]
    }


  },
  "humas": {
    "nama": "Biro Hubungan Masyarakat",
    "desc": "Biro Hubungan Masyarakat merupakan biro yang hadir dengan fungsi untuk menjaga citra positif BEM UI 2023, mempermudah alur komunikasi serta menyalurkan informasi ke biro/departemen/tim yang ada. Biro Humas juga memiliki fungsi untuk menjaga komunikasi dengan pemangku kepentingan terkait serta membangun relasi dengan pihak pihak eksternal.",
    "title1": "BIRO",
    "title2": "HUBUNGAN MASYARAKAT",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Aulia Rahmadita",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Achmad Kaelan Gaffardha Lubis",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Siti Hajar",
          "fakultas": "FIK 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Aqilah Salsabila",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Faras Khalisha Jatiputro",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ibnu Nur Ihsan",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Bintang Adiatma",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Rafid Nadhif Rizqullah",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Paskalia Zefanya Caesari",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rahmadhia Aisyah Putri",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Tasha Zulaikha Assyahidah",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ]
    }

  },
  "dkv": {
    "nama": "Biro Desain Komunikasi Visual",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "BIRO",
    "title2": "DESAIN KOMUNIKASI VISUAL",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Siti Fathonah Noviyanti",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Safra Aisyah Sahira",
          "fakultas": "FKG 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Anggita Budi Pratiwi",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Ahmad Zaidan Ifkar Hasbi",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Aisyah Abdul Aziz",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Dhia Amalia Setianti",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Intan Ayu Paramesti",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Karel Richard Fernando Loke",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Zaki Nur Said Hanan",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nabila Ratna A.",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Raihana Azra Syarif",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rizqi Zaidan",
          "fakultas": "FT 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Tyara Ayu Marcelline Azis",
          "fakultas": "FIK 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Wahyu Muhammad",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "psdm": {
    "nama": "Biro Pengembangan dan Sumber Daya Manusia",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "BIRO",
    "title2": "PENGEMBANGAN DAN SUMBER DAYA MANUSIA",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Salsabila H. Sastro",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Rudi Nurdiansyah",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Ahmad Hanafi",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Aufa Camelia Rahima Fairuza",
          "fakultas": "FF 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Christian Barnard Tanod",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Clara Lovita Barek Rebong",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Dzakwan Surya Putra",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Gabrielle Bianca Amarentia",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Hanif Ridho Yulianto",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Jasmine Kamilatun Nuha",
          "fakultas": "FKM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Keyika Sita Rae Aqilah Sukmawan",
          "fakultas": "FF 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Henry Sitohang",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nurul Huda",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Vanessa Luthfi Ayutya",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Willy Phillips",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Zakiya Solihatul Wahida",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }
  },
  "kestari": {
    "nama": "Biro Kesekretariatan",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "BIRO",
    "title2": "KESEKRE-TARIATAN",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Angelina Andriani Putri Suryo",
          "fakultas": "FK 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Lily Berliana Zahirah",
          "fakultas": "FH 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Choirunnisa Aurelia Putri R.",
          "fakultas": "FMIPA 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Daniah Kholda",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Lishandra Nurrayhan N.",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "M. Isaura Ranti Winandari",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Salsabila Nurohmah",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Shafa Fajriya Anindita Qurnain",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Shyfana Saskia Aulia",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Siti Qinaya Alizza",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Talitha Nazhifa Aslaa",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "litbang": {
    "nama": "Biro Penelitian dan Pengembangan",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "BIRO",
    "title2": "PENELITIAN DAN PENGEMBANGAN",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Christian Jonathan",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Reynard Parlinggoman S.",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Farah Salma Nisrina",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Aisyah Najma Agrina",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Aisyah Nurul Ilmi",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Almira Syafa Utami",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Brillian Mozza",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fernando Rizqi Juanito Joenoes",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rahmat Hafizh Mahasetyaputra",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Syahrani Tri Buwana Putri K.",
          "fakultas": "FKM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Vella Ananda",
          "fakultas": "FK 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "keuangan": {
    "nama": "Biro Keuangan",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "BIRO",
    "title2": "KEUANGAN",
    "cardData": {
      "Kepala Biro": [
        {
          "nama": "Nandini Juliani",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Biro Bidang Eksternal",
          "nama": "Tsabitah Safira",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Biro Bidang Internal",
          "nama": "Arina Cinta Ihlasia",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Biro": [
        {
          "nama": "Arnita Arthanidiya Maulisa Gusniar",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Clara Angelica Hotmaria Meilani M",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fiqri Syarif Haikal",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Hurayth Sabran",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rispa Maulana Sya'ban",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rizqiah Sandra Puspita",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Salsabila Sumantri",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Titania Priliani",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Vemas Matano Alfarizi",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Yosep Kissea Sigalingging",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "spi": {
    "nama": "Tim Satuan Pengendali Internal",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "TIM",
    "title2": "SATUAN PENGENDALI INTERNAL",
    "cardData": {
      "Wakil Kepala Tim Bidang Fungsional": [
        {
          "nama": "Ilham",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Tim Bidang Keuangan",
          "nama": "Siti Syafira Abdullah ",
          "fakultas": "FIA 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Tim Bidang Operasional",
          "nama": "Hanifah Putri Munawati",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Tim": [
        {
          "nama": "Dewita Zahra Nur Azizah",
          "fakultas": "FIK 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Eisya Quatrunnada",
          "fakultas": "FKG 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fadhly Prima",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Farsyah Novalino Hermadi",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Geri Andika Putra Wijaya",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Gibran Fuad Naoval",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Isfina Fadillah",
          "fakultas": "FIB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Marsya Alia Mufidah",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Azzam Lirobbani",
          "fakultas": "VOKASI 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Ilham Fauzan",
          "fakultas": "FIB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Vito Varianto",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rachmat Alif Prasetya",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Satrio Rifqi Wicaksono",
          "fakultas": "FMIPA 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Siti Ariqah Bakri",
          "fakultas": "FPSI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Tanaya Anindita Kafsah",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Yananda Devo Diego",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ]
    }


  },
  "sosmas": {
    "nama": "Departemen Sosial Masyarakat",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "SOSIAL MASYARAKAT",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Anatasya Shalsabilla",
          "fakultas": "FT 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Difa Alya Husna",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Maritza Adelia Syawal",
          "fakultas": "FKM 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Alden Luthfi",
          "fakultas": "FASILKOM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Azizah Salsabila Ibrahim",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Farida Doa Valentina",
          "fakultas": "FKM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Hanif Nasyawan",
          "fakultas": "FK 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ibni Shaquille Syauqi Ibrahim",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Lucia Deandra Audrey C.",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Madina Fathatunnisa",
          "fakultas": "FKG 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Maria Andriana Nea Candra",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Marsa Helda Putri Ihsaniah",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nouval Abdul Hakkam",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Puisi Wihdah",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Sultan Zachri Dipo Arifin",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },

      ]
    }

  },
  "dlh": {
    "nama": "Departemen Lingkungan Hidup",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "LINGKUNGAN HIDUP",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Azzam Auliarahman",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Ghazi Achmad Dzulfikar",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Putri Suryani Utami",
          "fakultas": "FKM 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Ali As'ad Hisyam Dzaki",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Anaka Irsa Santoso",
          "fakultas": "FKM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Gita Jilan Mahira",
          "fakultas": "FF 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Hairunnisa Hadiati Utami",
          "fakultas": "FKM 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ignatius Rama Ayodya Pratama",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Irham Taufiq",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "M. Fathur Rahman",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muh. Fadel Pebriansyah",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Regilza Alveronicha L. O.",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Reza Sya'bana Santoso Putra",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Salsa Nabila Ayuningtyas",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Syafiiqa Arina Gharizatul",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Yasmin Nashwa Alifya",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "adkesma": {
    "nama": "Departemen Advokasi dan Kesejahteraan Mahasiswa",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "ADVOKASI DAN KESEJAHTERAAN MAHASISWA",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Ahmad Yuma Prihanggoro E",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Kensha Lutfi Miranda",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Laura Tamariska",
          "fakultas": "FPSI 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Adilla Rahmatushiva",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Annisa Alya Aditya",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ayu Madana Dhasny",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Baiq Sasri Ferariyanti",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Deska Naufal Idris",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Farrel Fadheli Gunari",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ghina Zhafira",
          "fakultas": "FKG 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Arham Pebryan",
          "fakultas": "FIK 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Rakha Alfarabi",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nur Syahfawati",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Puspa Indah Hanan Sajida",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Restu Olivia Anthoni",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Vira Keumala Dewi",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Widdy Fatimah Azzahra",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "pim": {
    "nama": "Departemen Pemberdayaan dan Informasi Mahasiswa",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "PEMBERDAYAAN DAN INFORMASI MAHASISWA",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "M. Aqil Drajat Ramadhan",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Alessandra Dwi Maharani",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Clara Fredelina P.",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Azriel Dimas Ash_Shidiqi",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Caturangga Argya Nuriski",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Cherien Stevie Agustiara Suldi",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Diandra Srivania Irwandini",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fathimatul Muniyroh",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ghaida Nurul Fitri",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Mickieyo Thesanjustin",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Aprisal Hanafi",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "kastrat": {
    "nama": "Departemen Kajian Strategis",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "KAJIAN STRATEGIS",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Jhonas Nikson",
          "fakultas": "FH 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Moh. Ulul Azmi",
          "fakultas": "FEB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Siti Zahra Amaliya",
          "fakultas": "FKG 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Andri Maulana",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Bryan Renaldy",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Dinar Naufal Basith",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ibrahim Ghifar Hamadi",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Janitra Muriza Lathifah",
          "fakultas": "FEB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nabih Rijal Makarim",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nada Azka Maulida",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nur Muhammad Rizky Mubaroq",
          "fakultas": "FKG 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rizka Hapsari Putri",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Samuel Gabriel Manurung",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ultivia Ummi Syahiroh",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "akprob": {
    "nama": "Departemen Aksi dan Propaganda",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "AKSI DAN PROPAGANDA",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Prisalo Luis Graciano",
          "fakultas": "FMIPA 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Rendy Dharmawansyah",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Damara Zeta",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Agifta Grace Yossy",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ahmad Sabri Salim Alweni",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ferdian Irwan Prihandoko",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Kayla Exaudi Charisa Napitupulu",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Kayla Nadhira Wijaya",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Kiagus Abdul Haikal Juniro",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Rafid Naufal Akbar",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nayla Fathiyyatuz Zahra",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Valencia Christy Simajuntak",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Zain Nurmasupi",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Zharhine Alifia Haqque Putri",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "senbud": {
    "nama": "Departemen Seni dan Budaya",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "SENI DAN BUDAYA",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Adissa Keti Hapsari",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Bintang Fortuna",
          "fakultas": "FK 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Ibtisyam Hakim Seff",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Abraham Bintang Maha",
          "fakultas": "FIB 2020",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Arviola Marchsyalina S.",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Aulya Adilla",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Chonik K. Ramdhani",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Diandra Asha Inara",
          "fakultas": "FMIPA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Firstantya Aulia Rachmatyas M.",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Khalifa Rahman",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Tegar Bagaskara",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Sabrina Farhah Mumtazah",
          "fakultas": "FF 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Yehezkiel Gedy Garcia",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
      ]
    }

  },
  "depor": {
    "nama": "Departemen Olahraga",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "OLAHRAGA",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Ahmad Sef Afwafa Fuad",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Jalaluddin Rumi Assalam",
          "fakultas": "VOKASI 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Joyce Elen Maharani",
          "fakultas": "FISIP 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Abdul Fadly Dipantara",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Arvin Ghani Radistra",
          "fakultas": "FPSI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Dimas Muhammad Diariefaundra",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Exita Hanum Syahrial",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fahira Amaliya",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Fihir Fauzia",
          "fakultas": "FIB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Marc Salvadore Silitonga",
          "fakultas": "FASILKOM 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Melisa Widyatama",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Fauzan Rizqur R.",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "M Rafly Wardana",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rakha Akbar Aryaputra",
          "fakultas": "FISIP 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Shafy Urfa Haidar",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },        
      ]
    }

  },
  "pnk": {
    "nama": "Departemen Pendidikan dan Keilmuan",
    "desc": "Lorem ipsum dolor sit amet consectetur. Auctor condimentum nibh proin faucibus nullam id quam posuere eget. Risus elementum consectetur urna nunc arcu quis. Fermentum ligula euismod adipiscing amet nulla. Elit urna convallis tellus bibendum diam dolor sagittis nunc at.",
    "title1": "DEPARTEMEN",
    "title2": "PENDIDIKAN DAN KEILMUAN",
    "cardData": {
      "Kepala Departemen": [
        {
          "nama": "Laily Angelina",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "group": [
        {
          "jabatan": "Wakil Kepala Departemen Bidang Eksternal",
          "nama": "Octaviana Suryanti",
          "fakultas": "FMIPA 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "jabatan": "Wakil Kepala Departemen Bidang Internal",
          "nama": "Farhan Febrian",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        }
      ],
      "Staf Departemen": [
        {
          "nama": "Adonnai Phylosophia C. N.",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Chiara Dewi Chatlina",
          "fakultas": "VOKASI 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Marcello Cristiano",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammadi Daffa Radhitya",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Fakhru Rizal Abdillah",
          "fakultas": "FIA 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "M. Iqbal Banoza",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Ravi As Suminar",
          "fakultas": "FIB 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Muhammad Syahrul Ramadhan",
          "fakultas": "FT 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nabila Firza Dewanti Nugroho",
          "fakultas": "FEB 2021",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Nasywa Tsabitah Syahzanani",
          "fakultas": "FH 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Rufaida Kariemah",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },
        {
          "nama": "Ruth Debora Natalia Pardede",
          "fakultas": "FT 2022",
          "image": "https://engineering.unl.edu/images/staff/Kayla-Person.jpg"
        },        
      ]
    }

  },
  
};

export default mergedData;
