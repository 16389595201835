import React from 'react'
import './Navbar.css'
import LOGO from "../../img/home/LOGO.png"
import Dropdown from 'react-multilevel-dropdown'
import { Link } from "react-router-dom"

function Navbar() {
    return (
        <div className='navbar sticky' >
            <Link to={'/'}>
                <img src={LOGO} alt='not found' />
            </Link>

            <div>
                <Dropdown
                    title='Fungsionaris ▾' className='navlink'>
                    <Link className='dropdown-text' to={"/fungsionaris/pengurus-inti"}>
                        <Dropdown.Item className='dropdown-text'>
                            Pengurus Inti
                        </Dropdown.Item>
                    </Link>
                    <Link className='dropdown-text' to={"/fungsionaris/spi"}>
                        <Dropdown.Item className='dropdown-text'>
                            Satuan Pengendali Internal
                        </Dropdown.Item>
                    </Link>
                    <Dropdown.Item className='dropdown-text'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '270px' }}>
                            <span>
                                Kantor Komunikasi dan Informasi
                            </span>

                            {/* <span>
                                ▸
                            </span> */}
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/dkv"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Desain Komunikasi Visual
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/humas"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Hubungan Masyarakat
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/multimedia"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Multimedia
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Internal
                            </span>
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/kestari"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Keskretariatan
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/litbang"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Penltian dan Pengembangan
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/psdm"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Pengembangan Sumber Daya <br></br>Manusia
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Kemahasiswaan
                            </span>
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/adkesma"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Advokasi dan <br></br>Kesejahteraan Mahasiswa
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/pim"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Pemberdayaan dan <br></br>Inovasi Mahasiswa
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Sosial Politik
                            </span>
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/akprop"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Aksi dan Propaganda
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/kastrat"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Kajian Strategis
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Sosial Lingkungan
                            </span>

                            {/* <span>
                                ▸
                            </span> */}
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/sosmas"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Sosial Masyarakat
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/dlh"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Lingkungan Hidup
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Minat dan Bakat
                            </span>

                            {/* <span>
                                ▸
                            </span> */}
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/olahraga"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Olahraga
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/pdank"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Pendidikan dan <br></br>Keilmuan
                                </Dropdown.Item>
                            </Link>
                            <Link className='dropdown-text' to={"/fungsionaris/senbud"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Departemen Seni dan Budaya
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>

                    <Dropdown.Item className='dropdown-text'>
                        <div className='dropdown-witharrow'>
                            <span>
                                Bidang Keuangan
                            </span>
                        </div>
                        <Dropdown.Submenu position='right' className='dropdown-container' >
                            <Link className='dropdown-text' to={"/fungsionaris/keuangan"}>
                                <Dropdown.Item className='dropdown-text'>
                                    Biro Keuangan
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Submenu>
                    </Dropdown.Item>
                </Dropdown>

                <Link className='navlink' to={"/kajian"}>Kajian</Link>
                <Link className='navlink' to={"/dokumentasi"}>Dokumentasi</Link>
                <Link className='navlink' to={"/artikel"}>Artikel</Link>
                <Link className='navlink' to={"/uinfo"}>UInfo</Link>
                <Link className='navlink' to={"/hubungi-kami"}>Hubungi Kami</Link>

            </div>

        </div>
    )
}

export default Navbar