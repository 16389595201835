import React from 'react'
import './FungsionarisCard.css'


function FungsionarisCard({ img, name, fakultas }) {
    return (
        <div>
            <div className='fg-card-container'>
                <img style={{ width: '100%', borderRadius: '45px' }} src={img} />
                <div className='fg-card-title'>
                    <h1 className='card-nama'>{name}</h1>
                    <h2 className='card-fak'>{fakultas}</h2>
                </div>
            </div>
        </div>
    )
}

export default FungsionarisCard