import React from 'react'
import './FgBanner.css'
import { Card, Col, Container, Row } from 'react-bootstrap'

function FgBanner({ title1, title2, desc }) {
    return (
        <Container fluid className='birdep-bg'>
            <Row style={{ alignItems: 'center' }}>
                <Col md={7}>
                    <h2 className='header-title1'>
                        {title1}
                    </h2>
                    <h1 className='header-title2'> {title2}</h1>
                </Col>

                <Col md={5}>
                    <Card className='header-birdep-card'>
                        {desc}
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default FgBanner