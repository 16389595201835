import React from 'react'
import './Nilai.css'
import pic1 from "../../../img/home/pic1.png"
import pic2 from "../../../img/home/pic2.png"
import pic3 from "../../../img/home/pic3.png"

function Nilai() {
    return (
        <div>
            <h1 className='nilai-title'>Nilai Kami</h1>
            <div className='nilai-container'>
                <div className='nilai-card'>
                    {/* the front */}
                    <div className='nilai-front-card'>
                        <img src={pic1} width='100%' className='img-circle' />
                        <h2 className='nilai-text'>DEKAT</h2>
                    </div>
                    <div className='nilai-back-card'>
                        {/* the back */}
                        <h2 className='nilai-text' style={{ paddingTop: '30px' }}>DEKAT</h2>
                        <h3 className='nilai-back-text'>Loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum.</h3>
                    </div>
                </div>





                <div className='nilai-card'>
                    {/* the front */}
                    <div className='nilai-front-card'>
                        <img src={pic2} width='100%' className='img-circle' />
                        <h2 className='nilai-text'>PROAKTIF</h2>
                    </div>
                    <div className='nilai-back-card'>
                        {/* the back */}
                        <h2 className='nilai-text' style={{ paddingTop: '30px' }}>PROAKTIF</h2>
                        <h3 className='nilai-back-text'>Loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum.</h3>
                    </div>
                </div>




                <div className='nilai-card'>
                    {/* the front */}
                    <div className='nilai-front-card'>
                        <img src={pic3} width='100%' className='img-circle' />
                        <h2 className='nilai-text'>NYATA</h2>
                    </div>
                    <div className='nilai-back-card'>
                        {/* the back */}
                        <h2 className='nilai-text' style={{ paddingTop: '30px' }}>NYATA</h2>
                        <h3 className='nilai-back-text'>Loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum loren ipsum.</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nilai