import React from 'react'
import './BigContainer.css'
// import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Card, Col, Container, FormLabel, Row } from 'react-bootstrap';
import { Input } from 'antd';
import email from "../../img/home/emailicon.png";
import map from '../../img/home/map.jpeg'


const { TextArea } = Input;

function BigContainer() {

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className='header-section'>
                        <h1 className='header-title'>
                            HUBUNGI
                        </h1>
                        <h1 className='header-title' style={{ color: '#502033' }}>
                            KAMI
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-around bigcon-section'>
                {/* left side */}
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div >
                            <h1 className='left-title'>Narahubung</h1>
                            <h1 className='left-text'>Uli (Humas)</h1>
                            <h1 className='left-subtext'>085863212189</h1>
                            <h1 className='left-subtext'>@opl2452d</h1>
                            {/* <div>
                                <img src={email} />
                                <h1 className='left-subtext'>bem@ui.ac.id</h1>
                            </div> */}
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <img src={email} />
                                </div>
                                <div >
                                    <span className='left-subtext' >bem@ui.ac.id</span>
                                </div>
                            </div>

                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h1 className='left-title'>Pengaduan</h1>
                            <h1 className='left-text'>Yuma (Adkesma)</h1>
                            <h1 className='left-subtext'> 081384201100</h1>
                            <h1 className='left-subtext'>Lym.17</h1>
                            <h1 className='left-subtext'>bemui.adkesma@gmail.com</h1>
                        </div>
                    </div>
                    <div >
                        <h1 className='left-title'>Alamat</h1>
                        <p className='left-subtext'>Sekretariat BEM UI, Gedung Pusgiwa lama lt.2<br></br> Kampus UI Depok, Jawa Barat</p>
                        <a href='https://www.google.com/maps/place/Pusat+Kegiatan+Mahasiswa+(Pusgiwa)+Universitas+Indonesia/@-6.3655612,106.8147153,14.14z/data=!4m6!3m5!1s0x2e69ede360e863e9:0xd8f72fb9dd41569d!8m2!3d-6.3653881!4d106.8243239!16s%2Fg%2F11c178dn82?entry=ttu' target={'blank'}>
                            <img width={'100%'} style={{ borderRadius: '10px' }} src={map} />
                        </a>
                    </div>

                </Col>

                {/* right side */}
                <Col md={4}>
                    <Card className='feedback-card'>
                        <h1 className='feedback-text'>Beri Feedback</h1>
                        <p className='feedback-text2'>We would love to hear your thoughts and suggestions so we can improve!</p>
                        <br></br>
                        <Form.Label className='feedback-label'>Nama</Form.Label>
                        <Form.Control className='feedback-box' type="text" placeholder="" />
                        <br></br>
                        <Form.Label className='feedback-label'>Email</Form.Label>
                        <Form.Control className='feedback-box' type="email" placeholder="" />
                        <br></br>
                        <Form.Label className='feedback-label'>Pesan</Form.Label>
                        <TextArea className='feedback-box' rows={4} />
                        <br></br>
                        <Button className='feedback-button' variant="primary">Kirim</Button>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default BigContainer