import React from 'react'
import './Banner.css'

function Banner() {
    return (
        <div className='hp-banner'>
            <h1 className='hp-banner-title'> BEM UI </h1>
            <h2 className='hp-banner-subtitle'> Untuk UI dan Indonesia </h2>
        </div>
    )
}

export default Banner