import React from 'react'
import { Col, Row, Form, Pagination } from 'react-bootstrap'
import Dropdown from 'react-multilevel-dropdown'
import './Banner.css'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Search from 'antd/es/input/Search';


const onSearch = (value) => console.log(value);

function Banner() {
    return (
        <div className='ar-banner-section'>
            <h1 className='ar-banner-title'>ARTIKEL</h1>
            <h1 className='ar-banner-sub-title'>BEM UI</h1>
            <br></br>
            <Row style={{ justifyContent: 'center' }}>
                <Col md='8'>
                    {/* <Form.Control type="text" placeholder="Cari artikel" /> */}
                    <Space direction="vertical">
                        <Search
                            placeholder="Cari artikel"
                            onSearch={onSearch}
                            style={{
                                width: 700,
                            }}
                            size='large'
                        />
                    </Space>
                </Col>
            </Row>

        </div>
    )
}

export default Banner