import React from 'react'
import { Col, Row, Card, Button, Carousel } from 'react-bootstrap'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'
import './KajianPage.css'
import artikelBanner from '../../../img/home/artikelbanner.png'
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
// Import styles
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
// Import styles
import '@react-pdf-viewer/search/lib/styles/index.css';
import kajian from '../../../img/home/kajian.png'

function KajianPage() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const fullScreenPluginInstance = fullScreenPlugin();
    const searchPluginInstance = searchPlugin();
    return (
        <div>
            <Navbar />
            <div className='kajian-container' >
                <img style={{ margin: '40px 0px', borderRadius: '30px' }} className='image-center' src={artikelBanner} />
                <b className='kajian'>KAJIAN</b>
                <h1 className='kajianPg-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</h1>
                <p className='tanggal-kajian'>Dirilis pada 22 Agustus 2022</p>
                <div className='kj-fak-container'>
                    <h4 className='kj-fak-text'>KESEHATAN</h4>
                    <h4 className='kj-fak-text'>COVID-19</h4>
                    <h4 className='kj-fak-text'>BEM UI</h4>
                    <h4 className='kj-fak-text'>BEM FK UI</h4>
                    <h4 className='kj-fak-text'>BEM FKM UI</h4>
                    <h4 className='kj-fak-text'>BEM FKG UI</h4>
                    <h4 className='kj-fak-text'>BEM FF UI</h4>
                    <h4 className='kj-fak-text'>BEM FPsi UI</h4>
                    <h4 className='kj-fak-text'>BEM FIK UI</h4>
                    <h4 className='kj-fak-text'>ISMKI</h4>
                    <h4 className='kj-fak-text'>HPS</h4>
                </div>
            </div>
            <div style={{ background: '#9C3B63', padding: '50px 0px', width: '100vw' }}>
                <Row className='justify-content-center'>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div style={{ width: '90vw', height: '80vh' }}>
                            <Viewer plugins={[defaultLayoutPluginInstance, fullScreenPluginInstance, searchPluginInstance]} fileUrl="https://media.neliti.com/media/publications/93603-ID-none.pdf" />;
                        </div>
                    </Worker>

                </Row>
            </div>

            <div style={{ padding: '0px 80px', margin: '80px 0px' }}>
                <h1 className='sikap1-title2'>KAJIAN LAINNYA</h1>
                <br></br>
                <Carousel>
                    <Carousel.Item>
                        <Row className='justify-content-evenly'>
                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Row className='justify-content-evenly'>
                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Row className='justify-content-evenly'>
                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px' }}>
                                <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                                <Card.Body >
                                    <Card.Title className='judul-kajian'>
                                        Rilis Kajian Seri 1 COVID-19:
                                        Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                    </Card.Title>
                                    <Card.Text className='tanggal-kajian'>
                                        12 April 2023
                                    </Card.Text>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM UI</h4>
                                        <h4 className='card-fak-text'>BEM FK UI</h4>
                                        <h4 className='card-fak-text'>BEM FKM UI</h4>
                                        <h4 className='card-fak-text'>BEM FKG UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>BEM FIK UI</h4>
                                        <h4 className='card-fak-text'>BEM FF UI</h4>
                                        <h4 className='card-fak-text'>BEM FPsi UI</h4>
                                    </div>
                                    <div className='card-fak-container'>
                                        <h4 className='card-fak-text'>ISMKI</h4>
                                        <h4 className='card-fak-text'>HPS</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Carousel.Item>
                </Carousel>
            </div>

            <Footer />
        </div>
    )
}

export default KajianPage