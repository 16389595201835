import React from 'react'
import Container from '../../components/Container/Container'
import FgLayout from '../../components/Fungsionaris/FgLayout'
import FgBanner from '../../components/Fungsionaris/FgBanner'
import { useParams } from 'react-router'
import mergedData from '../../data/fungsionaris'
import HeaderPI from '../../components/Fungsionaris/HeaderPI'

function FungsionarisPage() {
    const params = useParams()
    const data = mergedData[params.fungsionarisName]
    return (
        <Container>
            {
                params.fungsionarisName == "pengurus-inti" ? <HeaderPI /> : <FgBanner title1={data.title1} title2={data.title2} desc={data.desc} />
            }
            <FgLayout data={data.cardData} />
        </Container >
    )
}

export default FungsionarisPage