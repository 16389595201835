import React from 'react'
import './HeaderPI.css'

function HeaderPI() {
    return (
        <div className='fg-header'>
            <h1 className='hd-title'>PENGURUS INTI</h1>
            <h2 className='hd-sub-title'>BEM UI 2023</h2>
        </div>
    )
}

export default HeaderPI