import './SignIn.css';
import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import image from '../img/DRIP_14.png';

const firebaseConfig = {
  apiKey: "AIzaSyCHxINxdqj2a6ExR96Av5vS4JgtUVF-8Bg",
  authDomain: "bem-ui-main-website.firebaseapp.com"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

function SignIn() {
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');
  const [password, setPassword] = useState(localStorage.getItem('userPassword') || '');
  const [rememberMe, setRememberMe] = useState(!!localStorage.getItem('userEmail'));

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (rememberMe) {
          localStorage.setItem('userEmail', email);
          localStorage.setItem('userPassword', password);
        } else {
          localStorage.removeItem('userEmail');
          localStorage.removeItem('userPassword');
        }
        window.location.href = '/admin/dashboard';
      })
      .catch((error) => {
        alert('Invalid login credentials');
      });
  }

  return (
    <Container fluid style={{ background: 'var(--grad, linear-gradient(135deg, #6D2845 0%, #AC426E 100%)' }}>
      <Row className='align-items-center justify-content-center' style={{ height: '100vh' }}>
        <Col md={5}>
          <Card className='sign-in-card' style={{ borderRadius: '20px', padding: '25px', }}>
            <Card.Body>
              <Card.Title className='card-title-admin'>Sign In</Card.Title>
              <Form.Control
                style={{ padding: '12px' }}
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <Form.Control
                style={{ padding: '12px' }}
                type="password"
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <Form.Group className='card-text'>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Form.Check
                    id="rememberMeCheck"
                    style={{ fontSize: '15px' }}
                    checked={rememberMe}
                    required
                    label="Remember me"
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                </div>
              </Form.Group>
              <br />
              <div className="d-grid gap-2">
                <Button onClick={handleLogin} className='card-button' style={{ padding: '12px' }}>Log in</Button>
              </div>
              <br />
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <img className='d-none d-md-block' style={{ width: '600px' }} src={image} />
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
