import React from 'react'
import './Store.css'

function Store() {
    return (
        <div className='store-container'>
            <div >
                {/* playstore */}
                <img style={{ marginTop: '4px', marginRight: '40px', width: '250px' }} src='https://www.suryalaya.co.id/wp-content/uploads/2019/06/get-it-on-google-play-png-apple-and-the-apple-logo-are-trademarks-of-apple-inc-registered-in-the-u-s-and-other-countries-app-store-is-a-service-mark-of-apple-inc-registered-in-826.png' />
            </div>
            <div >
                {/* appstore */}
                <img style={{ width: '250px' }} src='https://images.squarespace-cdn.com/content/v1/59d81702bebafb24a35368bf/1592152856822-EQ0KY7PLJJI3HPTRY31Z/app-store-png-logo-33123.png' />
            </div>
        </div>
    )
}

export default Store