import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Uinfo/Banner'
import Fitur from '../components/Uinfo/Fitur'
import Store from '../components/Uinfo/Store'

function Uinfo() {
    return (
        <div>
            <Navbar />
            <Banner />
            <Store />
            <Fitur />
            <Footer />

        </div>
    )
}

export default Uinfo