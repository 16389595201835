import React, { useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'
import DokBanner from '../DokBanner'
import './Sikap.css'

function Sikap() {
    const [kategori, setKategori] = useState('rilis sikap')
    const Test = (value) => {
        console.log(value)
        setKategori(value)
    }
    return (
        <Container fluid>
            <Row style={{ background: 'var(--grad, linear-gradient(135deg, #6D2845 0%, #AC426E 100%))' }}>
                <Col md={12}>
                    <DokBanner kategori={kategori} onChange={Test} />
                </Col>
            </Row>
            <Row style={{ background: '#F3F3F3' }}>
                <Col>
                    <div style={{ padding: '0px 80px', margin: '50px 0px' }}>

                        <h1 className='dok-title'>{kategori.toUpperCase()}</h1>

                        <Card style={{ background: 'white', boxShadow: '0px 14px 40px 0px rgba(112, 144, 176, 0.20)', borderRadius: '15px', padding: '35px' }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Col md={10}>
                                    <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                                </Col>
                                <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                            </div>
                            <hr style={{ background: '#B74675' }}></hr>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Col md={10}>
                                    <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                                </Col>
                                <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                            </div>
                            <hr style={{ background: '#B74675' }}></hr>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Col md={10}>
                                    <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                                </Col>
                                <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                            </div>
                            <hr style={{ background: '#B74675' }}></hr>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Col md={10}>
                                    <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                                </Col>
                                <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                            </div>
                            <hr style={{ background: '#B74675' }}></hr>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Col md={10}>
                                    <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                                </Col>
                                <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Sikap