import React from 'react'
import { Col, Row, Dropdown, Form, Container } from 'react-bootstrap'
import './DokBanner.css'


function DokBanner({ kategori, onChange }) {
    const handleChange = (e) => {
        onChange(e.target.value)
    }
    return (

        <div className='banner-section'>
            <h1 className='banner-title'>DOKUMEN</h1>
            <h1 className='banner-sub-title'>BEM UI</h1>
            <br></br>
            <Row style={{ justifyContent: 'center' }}>

                <Form.Select value={kategori} onChange={handleChange} style={{ width: '400px' }} aria-label="Default select example">
                    <option className='option-style' value="rilis sikap"  >Rilis Sikap</option>
                    <option className='option-style' value="policy brief" >Policy Brief</option>
                    <option className='option-style' value="laporan keuangan">Laporan Keuangan</option>
                    <option className='option-style' value="rilis pers">Rilis Pers</option>
                    <option className='option-style' value="notula">Notula</option>
                    <option className='option-style' value="pedoman">Pedoman</option>
                </Form.Select>

            </Row>

        </div>

    )
}

export default DokBanner