import React from 'react'
import './Visimisi.css'

function Visimisi() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
            <h1 className='Visimisi-title'>Visi Misi</h1>
            <div className='Visimisi-section'>
                <p className='Visimisi-text'>
                    "BEM UI yang secara <span class="Visimisi-dark">Proaktif</span> menghasilkan keberdampakan yang
                    <br></br>
                    <span class="Visimisi-dark">Nyata</span> dan terasa <span class="Visimisi-dark">Dekat</span> bagi IKM UI dan Indonesia"
                </p>
            </div>

            <div className='Visimisi-section Visimisi-padding'>

                <div className='Visimisi-container'>
                    <div className='Visimisi-listnum'>
                        <span className='Visimisi-dark'>01</span>
                    </div>

                    <div className='Visimisi-text2'>
                        <span> Menciptakan tata kelola internal yang adaptif dan menyenangkan demi hadirnya sumber daya dan peranan organisasi yang optimal</span>

                    </div>
                </div>

                <div className='Visimisi-container'>
                    <div className='Visimisi-listnum'>
                        <span className='Visimisi-dark'>02</span>
                    </div>
                    <div className='Visimisi-text2'>
                        <span>Menghadirkan relasi yang sinergis dan konstruktif dengan beragam elemen demi tercapainya kebermanfaatan bagi IKM UI dan Indonesia</span>

                    </div>

                </div>

                <div className='Visimisi-container'>
                    <div className='Visimisi-listnum'>
                        <span className='Visimisi-dark'>03</span>
                    </div>
                    <div className='Visimisi-text2'>
                        <span >Mewujudkan gerakan sosial, politik, dan lingkungan hidup yang strategis, partisipatif, dan nyata</span>

                    </div>

                </div>

                <div className='Visimisi-container'>
                    <div className='Visimisi-listnum'>
                        <span className='Visimisi-dark'>04</span>
                    </div>
                    <div className='Visimisi-text2'>
                        <span >Meningkatkan kualitas advokasi yang strategis terhadap kebutuhan dan
                            kesejahteraan IKM UI</span>

                    </div>

                </div>

                <div className='Visimisi-container'>
                    <div className='Visimisi-listnum'>
                        <span className='Visimisi-dark'>05</span>
                    </div>
                    <div className='Visimisi-text2'>
                        <span>Menghadirkan wadah partisipasi yang inklusif dan apresiatif dalam upaya pengembangan potensi minat dan bakat IKM UI</span>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Visimisi