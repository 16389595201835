import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'
import './Sikap1.css'
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
// Import styles
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
// Import styles
import '@react-pdf-viewer/search/lib/styles/index.css';


function Sikap1() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const fullScreenPluginInstance = fullScreenPlugin();
    const searchPluginInstance = searchPlugin();
    return (
        <div>
            <Navbar />
            <div className='sikap1-container' >
                <b className='sikap1-header'>RILIS SIKAP</b>
                <h1 className='sikap1-title'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</h1>
                <p className='sikap1-tanggal'>Dirilis pada 22 Agustus 2022</p>
            </div>
            <div style={{ background: '#9C3B63', padding: '50px 0px', width: '100vw' }}>
                <Row className='justify-content-center'>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div style={{ width: '90vw', height: '80vh' }}>
                            <Viewer plugins={[defaultLayoutPluginInstance, fullScreenPluginInstance, searchPluginInstance]} fileUrl="https://media.neliti.com/media/publications/93603-ID-none.pdf" />;
                        </div>
                    </Worker>

                </Row>
            </div>

            <div style={{ padding: '0px 80px', margin: '80px 0px' }}>
                <h1 className='sikap1-title2'>DOKUMEN LAINNYA</h1>
                <br></br>
                <Card style={{ background: 'white', boxShadow: '0px 14px 40px 0px rgba(112, 144, 176, 0.20)', borderRadius: '15px', padding: '35px 35px 20px 35px' }}>
                    <Row >
                        <Col sm={10}>
                            <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                        </Col>
                        <Col sm={2}>
                            <Button href='rilis-sikap-1' className='sikap-button-text'>Baca Disini</Button>
                        </Col>
                        <hr style={{ background: '#B74675' }}></hr>
                        <Col sm={10}>
                            <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                        </Col>
                        <Col sm={2}>
                            <Button className='sikap-button-text'>Baca Disini</Button>
                        </Col>
                        <hr style={{ background: '#B74675' }}></hr>
                        <Col sm={10}>
                            <p className='sikap-text'>Mengecam Tindakan Kepolisian Menangkap dan Menghalang-halangi Bantuan Hukum Bagi Massa Aksi Tolak KUHP di Bandung</p>
                        </Col>
                        <Col sm={2}>
                            <Button className='sikap-button-text'>Baca Disini</Button>
                        </Col>
                    </Row>
                </Card>
            </div>

            <Footer />
        </div>
    )
}

export default Sikap1