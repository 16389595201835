import React, { useState } from 'react';
import { Card, Form, InputGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import Layout from './Layout';
import './Shortener.css';

function isURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
}

function Shortener() {
    const [longUrl, setLongUrl] = useState("");
    const [customUrl, setCustomUrl] = useState("");
    const [history, setHistory] = useState([]);

    const shortenUrl = async () => {
        if (!isURL(longUrl)) {
            alert('Masukkan URL yang valid.');
            return;
        }

        try {
            const response = await axios.post('http://localhost:8000/api/shorten/', {
                original_url: longUrl,
                shortened_url: customUrl
            });

            const newRecord = {
                original: longUrl,
                shortened: response.data.shortened_url
            };

            setHistory([newRecord, ...history]);
        } catch (error) {
            console.log("Terjadi error: ", error);
        }
    };

    return (
        <div>
            <Layout>
                <h1 className='title'>URL Shortener</h1>
                <Card className='sh-card-container'>
                    <Form.Control
                        style={{ height: '50px' }}
                        type="url"
                        placeholder="Enter your long url"
                        value={longUrl}
                        onChange={(e) => setLongUrl(e.target.value)}
                    />
                    <br />
                    <InputGroup className="mb-3" style={{ height: '50px' }}>
                        <InputGroup.Text id="basic-addon3">
                            🔗 bemui.link/
                        </InputGroup.Text>
                        <Form.Control
                            id="basic-url"
                            aria-describedby="basic-addon3"
                            value={customUrl}
                            onChange={(e) => setCustomUrl(e.target.value)}
                        />
                    </InputGroup>
                    <div className="d-grid gap-2">
                        <Button onClick={shortenUrl} className='card-button' style={{ padding: '12px', marginTop: '30px' }}>
                            Shorten
                        </Button>
                    </div>
                </Card>
                <h1 className='sh-history'>History</h1>
                {history.map((item, index) => (
                    <div key={index} style={{ padding: '10px 70px' }}>
                        <span className='title-history'>bemui.link/{item.shortened}</span>
                        <br />
                        <span className='sub-title-history'>{item.original}</span>
                        <hr style={{ borderTop: '2px solid #CCCCCC' }} />
                    </div>
                ))}
            </Layout>
        </div>
    );
}

export default Shortener;
