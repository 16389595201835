import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './ArtikelPage.css'
import artikelBanner from '../../img/home/artikelbanner.png'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function ArtikelPage() {
    return (
        <Row>
            <Col>
                <Navbar />
                <div style={{ padding: '100px' }}>

                    <img style={{ margin: '40px 0px', borderRadius: '30px' }} className='image-center' src={artikelBanner} />

                    <h2 className='artikel'>ARTIKEL</h2>
                    <h1 className='judul-artikel'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</h1>
                    <div style={{ display: 'flex' }}>

                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />

                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>
                    </div>

                    <Card className='artikel-card'>
                        <p>
                            Lorem ipsum dolor sit amet consectetur. Volutpat neque dictumst dolor vel dignissim nullam. Purus magna at vestibulum risus facilisi habitasse sem. Sem euismod nec aenean ac natoque euismod consequat diam eu. Morbi amet aliquam mattis vel. Interdum suspendisse neque aliquam ultrices ultrices amet malesuada volutpat et. A amet nullam arcu ac quis. Sodales dui aenean feugiat feugiat sed sodales enim felis. Lobortis congue habitasse vel urna pharetra risus facilisis.
                            <br />
                            <br />
                            Sapien amet arcu velit cursus ac. Sed sed quis a magna eu at rhoncus vulputate magna. Tristique porta gravida enim sed sed. Integer vestibulum sed imperdiet sit ultrices molestie lectus. Lacus neque vel sed sagittis et lectus neque.
                            <br />
                            <br />
                            Diam at nulla elementum elementum sit id lorem. Donec amet sit in at enim integer praesent orci. Metus turpis arcu leo egestas. Dictum amet fringilla adipiscing venenatis. Diam imperdiet aliquam nunc magna pellentesque tellus fringilla sodales tellus. Velit faucibus proin id ultrices porttitor viverra consectetur. Placerat ut tristique amet cum morbi pretium. Volutpat sed velit nibh faucibus luctus consequat. Leo dictum odio scelerisque platea. Id ornare nunc diam hac. Nec quisque mattis eu aliquet praesent in adipiscing.
                            <br />
                            <br />
                            Sit erat aliquet fermentum accumsan eu morbi nibh in. Eget sit sagittis dictumst adipiscing sed amet. Amet nec tincidunt odio sagittis faucibus viverra sodales. Nec vel et cursus commodo malesuada tincidunt. Netus consequat ultrices habitasse habitant amet integer neque non condimentum. Sit habitant consectetur congue tellus. Turpis non pharetra ac posuere etiam. Justo augue et at amet. Laoreet sit viverra nibh accumsan urna ac. Tortor mattis rhoncus eu nulla at. Sagittis dictum fermentum arcu urna id odio imperdiet massa massa. Tincidunt malesuada commodo volutpat metus dignissim tristique vitae. Commodo adipiscing sapien nunc nulla ornare leo.
                            Vulputate dictumst vel volutpat volutpat eget urna enim justo. Malesuada vel in quam sed elementum vitae id pulvinar elementum. Dignissim quisque turpis elit quam nunc. Tristique risus nibh volutpat in lobortis. Maecenas accumsan vitae cras felis. Gravida habitant nulla vitae eleifend a volutpat nulla cras. Est euismod commodo sodales ut ut venenatis.
                            <br />
                            <br />
                            Donec et est mi sed tellus eros faucibus donec at. Vitae eu tortor est quam nullam sed. Varius dictum vitae rhoncus at venenatis magnis placerat vel bibendum. Habitant semper vitae risus vivamus. Amet ultrices at sit at egestas. Tristique nisl consequat dictum tempor fermentum tellus. Sit quam enim augue fames. Auctor ac amet egestas neque maecenas sed cursus et. Egestas convallis interdum sed sed netus scelerisque montes risus egestas.
                        </p>
                    </Card>



                    <div style={{ padding: '0px 20px' }}>

                        <h2 className='artikel' style={{ textAlign: 'center', marginBottom: '40px' }}>ARTIKEL LAINNYA</h2>
                        <Card style={{ background: 'white', boxShadow: '0px 14px 40px 0px rgba(112, 144, 176, 0.20)', borderRadius: '15px', padding: '30px 50px' }}>
                            <Row className='align-items-center'>
                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <p className='artikel-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</p>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img width={'60%'} style={{ borderRadius: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                            <hr style={{ background: '#B74675' }}></hr>
                            <Row className='align-items-center'>
                                <Col sm={10} >
                                    <div style={{ display: 'flex' }}>

                                        <img style={{ borderRadius: '50%', width: '30px', marginRight: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />


                                        <h3 className='artikel-profile'><u>Defani Shafa</u>  • 22 Agustus 2022</h3>

                                        {/* <Col md={2}>
                                            <h4 className='artikel-tanggal'>22 Agustus 2022</h4>
                                        </Col> */}
                                    </div>
                                    <p className='artikel-title'>BEM UI Merilis UInfo, Aplikasi Dari Mahasiswa, Oleh Mahasiswa, Untuk Mahasiswa</p>
                                    <p className='artikel-highlight'>Vivamus fringilla pretium ante, ac vulputate ex eleifend vestibulum. Nulla facilisi. Maecenas ac pharetra tellus. Quisque congue odio lobortis suscipit facilisis. Quisque dictum dignissim tempor. Aenean a arcu erat. Quisque vel sceler...</p>
                                </Col>
                                <Col sm={2}>
                                    <img width={'60%'} style={{ borderRadius: '10px' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSPPMk59fuf8eied0LZ_L8ehJDIsz_yROu7BBeG6ut1J5KHhUXU5fdwCab3tQiuDSCC8&usqp=CAU' />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>


                <Footer />

            </Col>
        </Row>
    )
}

export default ArtikelPage