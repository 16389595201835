import React, { useEffect, useState } from 'react'
import './Kajian.css'
import kajian from "../../../img/home/kajian.png"
import axios from 'axios'
import { Card, Col, Row } from 'react-bootstrap'


function Kajian() {
    const [data, setData] = useState([])
    useEffect(() => {
        fetchdata()
    }, [])

    const fetchdata = async () => {
        const response = await axios.get('https://fakestoreapi.com/products')
        console.log(response.data)
        setData(response.data.slice(-3))
    }

    return (
        <div className='kajian-section' style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Row className='justify-content-between'>
                {data.map((item) => {
                    return <Col>
                        <Card style={{ width: '20rem', borderRadius: '30px', padding: '0px', border: 'none' }}>
                            <Card.Img variant="top" style={{ borderRadius: '30px 30px 0px 0px' }} src={kajian} />
                            <Card.Body >
                                <Card.Title className='judul-kajian-card'>
                                    Rilis Kajian Seri 1 COVID-19:
                                    Transisi Pandemi Menjadi Endemi, Siapkah Negeri Ini?
                                </Card.Title>
                                <Card.Text className='tanggal-kajian'>
                                    12 April 2023
                                </Card.Text>
                                <div className='card-fakultas-container'>
                                    <h4 className='card-fakultas-text'>BEM UI</h4>
                                    <h4 className='card-fakultas-text'>BEM FK UI</h4>
                                    <h4 className='card-fakultas-text'>BEM FKM UI</h4>
                                    <h4 className='card-fakultas-text'>BEM FKG UI</h4>
                                </div>
                                <div className='card-fakultas-container'>
                                    <h4 className='card-fakultas-text'>BEM FIK UI</h4>
                                    <h4 className='card-fakultas-text'>BEM FF UI</h4>
                                    <h4 className='card-fakultas-text'>BEM FPsi UI</h4>
                                </div>
                                <div className='card-fakultas-container'>
                                    <h4 className='card-fakultas-text'>ISMKI</h4>
                                    <h4 className='card-fakultas-text'>HPS</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                })}
            </Row>






            <div>
                <h1 className='kajian-title'>Kajian <br></br>Aliansi <br></br>BEM Se-UI</h1>
                <a href='/kajian' className='kajian-link'>Selengkapnya ↗ </a>
            </div>


        </div>
    )
}

export default Kajian