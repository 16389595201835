import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Home/Banner/Banner'
import Deskripsi from '../components/Home/Deskripsi/Deskripsi'
import Visimisi from '../components/Home/VisMis/Visimisi'
import Nilai from '../components/Home/Nilai/Nilai'
import Kalender from '../components/Home/Kalender/Kalender'
import Proker from '../components/Home/Proker/Proker'
import Kajian from '../components/Home/Kajian/Kajian'
import Uinfo from '../components/Home/UInfo/Uinfo'
import Footer from '../components/Footer/Footer'

function Home() {
    return (
        <div>
            <Navbar />
            <Banner />
            <Deskripsi />
            <Nilai />
            <Visimisi />
            <Kalender />
            <Proker />
            <Kajian />
            <Uinfo />
            <Footer />
        </div>
    )
}

export default Home