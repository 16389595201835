import React from 'react'
import './Footer.css'
import location from "../../img/home/locicon.png";
import call from "../../img/home/callicon.png";
import email from "../../img/home/emailicon.png";
import IG from "../../img/home/instagram.png";
import line from "../../img/home/line.png";
import twitter from "../../img/home/twitter.png";
import tiktok from "../../img/home/tiktok.png";
import FB from "../../img/home/FB.png";
import linkedin from "../../img/home/linkedin.png";
import icon from "../../img/home/icon.png";
import appstore from "../../img/home/appstore_footer.png";
import playstore from "../../img/home/playstore_footer.png";
import YT from "../../img/home/youtube.png";


function Footer() {
    return (
        <div className='footer-section' style={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* left */}
            <div>
                {/* first line */}
                <h1 className='footer-title'>Badan Eksekutif Mahasiswa Universitas Indonesia</h1>

                {/* second line */}
                <h2 className='footer-text1'> © BEM UI 2023</h2>

                {/* next 3 lines */}
                <div className='footer-container'>
                    {/* location*/}
                    <div style={{ alignItems: 'center' }}>
                        <img src={location} />
                    </div>
                    <div >
                        <span className='footer-text2' style={{ marginLeft: '14px' }}>Sekretariat BEM UI, Gedung Pusgiwa lama lt.2 Kampus UI Depok, Jawa Barat</span>
                    </div>
                </div>

                <div className='footer-container'>
                    {/* email */}
                    <div style={{ alignItems: 'center' }}>
                        <img src={email} />
                    </div>
                    <div >
                        <span className='footer-text2' >bem@ui.ac.id</span>
                    </div>
                </div>

                <div className='footer-container'>
                    {/* call number */}
                    <div style={{ alignItems: 'center' }}>
                        <img src={call} />
                    </div>
                    <div >
                        <span className='footer-text2' style={{ marginLeft: '12px' }}>Uli (085863212189)</span>
                    </div>
                </div>
            </div>

            {/* right */}
            <div>
                {/* first line */}
                <h1 className='footer-title' style={{ marginBottom: '15px' }}>Ikuti Kami</h1>
                <div style={{ display: 'flex' }}>
                    {/* first line */}
                    <div >
                        <img style={{ marginRight: '15px' }} src={IG} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={FB} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={line} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={twitter} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={tiktok} />
                    </div>
                </div>

                {/* second line */}
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    {/* first line */}
                    <div >
                        <img style={{ marginRight: '15px' }} src={linkedin} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={YT} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={icon} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={playstore} />
                    </div>
                    <div>
                        <img style={{ marginRight: '15px' }} src={appstore} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer