import React from 'react'
import './Kalender.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';


function Kalender() {
    return (
        <div className='kalender-section'>
            <h1 className='kalender-title'>Kalender BEM UI</h1>
            <Link className='kalender-link'>Selengkapnya ↗ </Link>

            <div className='kalender-parent'>
                <div className='kalender-pic'>
                    <h2 className='kalender-text1'>APRIL 2023</h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar className='kalender-component' defaultValue={dayjs()} />
                    </LocalizationProvider>
                </div>

                <div className='kalender-pic' style={{ width: '55%' }}>
                    <h2 className='kalender-text2'>25 April 2023<hr style={{ width: '250px' }}></hr></h2>
                    <h1 className='kalender-highlight '>Pra-UKK UI YEA</h1>
                    <h1 className='kalender-highlight'>Pra-UKK UI Art War</h1>
                </div>

            </div>
        </div>
    )
}

export default Kalender