import React from 'react'
import DokBanner from '../components/Dokumentasi/DokBanner'
import Sikap from '../components/Dokumentasi/Rilis Sikap/Sikap'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

function Dokumentasi() {
    return (
        <div>
            <Navbar />
            <Sikap />
            <Footer />
        </div>
    )
}

export default Dokumentasi